/* eslint-disable max-len */
import loadable from '@loadable/component';
import ReferralRegistration from 'app/ReferralRegistration';
import ReferralStoreRegistration from 'app/ReferralStoreRegistration';
import { AnimatePresence } from 'framer-motion';
import {
    Suspense, createContext, useEffect, useMemo,
} from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Routes, useLocation } from 'react-router-dom';

import LoadingScreen from 'components/loaders/loading-screen';
import RedirectToLocale from 'components/widgets/redirect-to';
import useLocale from 'hooks/locale/useLocale';
import { localeToLanguageMapper } from 'i18n/index';
import AppLayout from 'layout/app-layout';
import GoBackLayout from 'layout/go-back-layout';
import InstructionLayout from 'layout/instruction-layout';
import LocalizedLayout from 'layout/localized-layout';
import ProfileLayout from 'layout/profile-layout';
import ScanLayout from 'layout/scan-layout';
import VoucherSingleLayout from 'layout/voucher-single-layout';
import NotFound from 'pages/not-found';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import PrivateOutlet from 'routes/privateRoute';
import PublicOutlet from 'routes/publicRoute';

import FortuneWheelLayout from 'layout/fortune-wheel-layout/FortuneWheelLayout';
import { useExitPrompt } from 'hooks/window/useExitPrompt';
import StoryLayout from 'layout/story-layout/StoryLayout';
import { setStorageItem } from 'services/storage';
import CacheReporter from './cache/CacheReporter';
import useUpdateBuildVersion from './cache/useUpdateBuildVersion';
import ClickoutOffer from './clickout-offer/ClickoutOffer';
import { MAINTENANCE_TRIGGERED_KEY } from './maintenance/Maintenance';

const Maintenance = loadable(() => import('app/maintenance/Maintenance'));
const Instruction = loadable(() => import('pages/instruction'));
const Login = loadable(() => import('pages/authentication/login'));
const LoginOrange = loadable(() => import('pages/authentication/login-orange/LoginOrange'));
const SignUp = loadable(() => import('pages/authentication/sign-up'));
const CheckInscription = loadable(() => import('pages/authentication/check-inscription'));
const ResetPassword = loadable(() => import('pages/authentication/reset-password'));
const ResetPasswordCheckOtp = loadable(() => import('pages/authentication/reset-password-check-otp'));
const ResetPasswordNewPassword = loadable(() => import('pages/authentication/reset-password-new-password'));
const Pin = loadable(() => import('pages/authentication/pin'));
const Steps = loadable(() => import('pages/steps'));

const PrivacyPolicy = loadable(() => import('pages/support/privacy-policy'));
const HelpAndContact = loadable(() => import('pages/support/help-and-contact/HelpAndContact'));
const HelpCenter = loadable(() => import('pages/support/help-center/HelpCenter'));

const Profile = loadable(() => import('pages/profile'));
const ProfileEditTags = loadable(() => import('pages/profile/profile-edit-tags'));
const EditProfile = loadable(() => import('pages/profile/profile-edit'));
const ProfileReferrals = loadable(() => import('pages/profile/profile-referrals'));
const ProfileOrderHistory = loadable(() => import('pages/profile/profile-order-history'));
const ProfileLevels = loadable(() => import('pages/profile/profile-levels'));

const PointsHistory = loadable(() => import('pages/points-history'));
const Scan = loadable(() => import('pages/scan'));
const ScanId = loadable(() => import('pages/scan-id'));
const Home = loadable(() => import('pages/home'));
const Questions = loadable(() => import('pages/questions/Questions'));
const Story = loadable(() => import('pages/story/Story'));
const VoucherDetails = loadable(() => import('pages/voucher-details'));
const Store = loadable(() => import('pages/store'));
const StoreSingle = loadable(() => import('pages/store-single'));
const Wallet = loadable(() => import('pages/wallet'));
const ExpiredWallet = loadable(() => import('pages/wallet/wallet-expired'));
const WalletVoucherDetails = loadable(() => import('pages/wallet/wallet-voucher-details'));
const WalletVoucherDetailsHistory = loadable(() => import('pages/wallet/wallet-voucher-details-history'));

const FortuneWheel = loadable(() => import('pages/fortune-wheel/FortuneWheel'));

export const ExitPromptContext = createContext(null);

function App() {
    const lang = useLocale();
    const messages = localeToLanguageMapper[lang];
    const location = useLocation();

    // set maintenance to true
    useEffect(() => {
        setStorageItem(MAINTENANCE_TRIGGERED_KEY, false);
    }, []);

    const [shouldShowExitPrompt, setShouldShowExitPrompt] = useExitPrompt(false);
    const contextValue = useMemo(() => ({
        shouldShowExitPrompt,
        setShouldShowExitPrompt,
    }), [shouldShowExitPrompt, setShouldShowExitPrompt]);

    const {
        hasNewVersion,

        // isUpdateLoading,
        onUpdateStart,
        onUpdateCancel,
    } = useUpdateBuildVersion();

    return (
        <>
            <CacheReporter
                shouldShow={hasNewVersion}
                onClose={onUpdateCancel}
                onUpdate={onUpdateStart}
            />

            <ExitPromptContext.Provider value={contextValue}>
                <IntlProvider messages={messages} locale={lang} defaultLocale="fr">
                    <AnimatePresence exitBeforeEnter>
                        <Routes location={location} key={location.key}>
                            <Route path="*" element={<NotFound />} />
                            <Route index element={<RedirectToLocale />} />

                            <Route element={<PublicOutlet />}>
                                {/* click-out urls */}
                                <Route path="c/:clickoutCode" element={<ClickoutOffer />} />
                                {/* referral urls */}
                                <Route path="r/:referralCode" element={<ReferralRegistration />} />
                                <Route path="rs/:referralStoreId" element={<ReferralStoreRegistration />} />
                            </Route>


                            {Object.keys(All_PATHS_NAMESPACES).map((el, index) => (
                                <Route key={index} path={el} element={<LocalizedLayout lang={el} />}>
                                    <Route path={All_PATHS_NAMESPACES[el].maintenance.path} element={<Maintenance />} />

                                    <Route element={<PrivateOutlet />}>
                                        <Route path="" element={<AppLayout />}>
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <Home />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].questions.name}
                                            element={<AppLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <Questions />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].story.name}
                                            element={<StoryLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <Story />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].fortuneWheel.name}
                                            element={<FortuneWheelLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <FortuneWheel />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el]['help-center'].name}
                                            element={<ProfileLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <HelpCenter />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].points_history.name}
                                            element={<ProfileLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <PointsHistory />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route path={All_PATHS_NAMESPACES[el].pin.name}>
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <Pin />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].onboarding.name}
                                            element={<AppLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <Steps />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route path={All_PATHS_NAMESPACES[el].wallet.path}>
                                            <Route path="" element={<AppLayout />}>
                                                <Route
                                                    index
                                                    element={
                                                        <Suspense fallback={<LoadingScreen />}>
                                                            <Wallet />
                                                        </Suspense>
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path={All_PATHS_NAMESPACES[el].expired_wallet.name}
                                                element={<GoBackLayout />}
                                            >
                                                <Route
                                                    index
                                                    element={
                                                        <Suspense fallback={<LoadingScreen />}>
                                                            <ExpiredWallet />
                                                        </Suspense>
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path={All_PATHS_NAMESPACES[el]['wallet-voucher-details'].name}
                                                element={<VoucherSingleLayout />}
                                            >
                                                <Route
                                                    index
                                                    element={
                                                        <Suspense fallback={<LoadingScreen />}>
                                                            <WalletVoucherDetails />
                                                        </Suspense>
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path={
                                                    All_PATHS_NAMESPACES[el]['wallet-voucher-details-history'].name
                                                }
                                                element={<GoBackLayout />}
                                            >
                                                <Route
                                                    index
                                                    element={
                                                        <Suspense fallback={<LoadingScreen />}>
                                                            <WalletVoucherDetailsHistory />
                                                        </Suspense>
                                                    }
                                                />
                                            </Route>
                                        </Route>
                                        <Route path={All_PATHS_NAMESPACES[el].store.path}>
                                            <Route path="" element={<AppLayout />}>
                                                <Route
                                                    index
                                                    element={
                                                        <Suspense fallback={<LoadingScreen />}>
                                                            <Store />
                                                        </Suspense>
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path={All_PATHS_NAMESPACES[el].store_single.name}
                                                element={<GoBackLayout />}

                                                // element={
                                                //     <GoBackLayout backTo={All_PATHS_NAMESPACES[el].store.path} />
                                                // }
                                            >
                                                <Route
                                                    index
                                                    element={
                                                        <Suspense fallback={<LoadingScreen />}>
                                                            <StoreSingle />
                                                        </Suspense>
                                                    }
                                                />
                                            </Route>
                                        </Route>
                                    </Route>
                                    <Route element={<PrivateOutlet />}>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].profile.path}
                                            element={<ProfileLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <Profile />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path={All_PATHS_NAMESPACES[lang].referrals.name}
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <ProfileReferrals />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path={All_PATHS_NAMESPACES[lang].edit_tags.name}
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <ProfileEditTags />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path={All_PATHS_NAMESPACES[lang].levels.name}
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <ProfileLevels />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path={All_PATHS_NAMESPACES[lang].order_history.name}
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <ProfileOrderHistory />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path={All_PATHS_NAMESPACES[lang]['help-and-contact'].name}
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <HelpAndContact />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path={All_PATHS_NAMESPACES[el].edit_profile.name}
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <EditProfile />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                    </Route>
                                    <Route element={<PrivateOutlet />}>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el]['voucher-details'].path}
                                            element={<ProfileLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <VoucherDetails />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                    </Route>
                                    <Route element={<PrivateOutlet />}>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].scan.path}
                                            element={<ScanLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <Scan />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el]['scan-id'].path}
                                            element={<GoBackLayout />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <ScanId />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                    </Route>
                                    <Route element={<InstructionLayout />}>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].reset_password.name}
                                            element={<PublicOutlet />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <ResetPassword />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path={All_PATHS_NAMESPACES[el].reset_password_check_otp.name}
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <ResetPasswordCheckOtp />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path={All_PATHS_NAMESPACES[el].reset_password_new_password.name}
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <ResetPasswordNewPassword />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].sign_up.name}
                                            element={<PublicOutlet />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <SignUp />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path={All_PATHS_NAMESPACES[el].sign_up_check_pin.name}
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <CheckInscription />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].login.name}
                                            element={<PublicOutlet />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <Login />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].loginOrange.path}
                                            element={<PublicOutlet />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <LoginOrange />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={All_PATHS_NAMESPACES[el].instruction.name}
                                            element={<PublicOutlet />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<LoadingScreen />}>
                                                        <Instruction />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                    </Route>

                                    {/* <Route element={<PublicOutlet />}> */}
                                    <Route
                                        path={All_PATHS_NAMESPACES[el]['privacy-policy'].name}

                                        // element={!isEmpty(user) && <AppLayout />}
                                        element={<GoBackLayout />}
                                    >
                                        <Route
                                            index
                                            element={
                                                <Suspense fallback={<LoadingScreen />}>
                                                    <PrivacyPolicy />
                                                </Suspense>
                                            }
                                        />
                                    </Route>
                                    {/* </Route> */}
                                </Route>
                            ))}
                        </Routes>
                    </AnimatePresence>
                </IntlProvider>
            </ExitPromptContext.Provider>
        </>
    );
}

export default App;
